<template>
  <section :class="$style.teamMembers">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectTeamMember"
          @handleFindItems="handleFindTeamMembers"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button type="primary" @click="$router.push('team-members/create')">
        Создать сотрудника
      </el-button>
    </div>
    <el-table :data="teamMembers" stripe>
      <el-table-column prop="orderField" label="Приоритет"></el-table-column>
      <el-table-column prop="name" label="Имя сотрудника"></el-table-column>
      <el-table-column prop="jobTitle" label="Должность"> </el-table-column>
      <el-table-column prop="email" label="Email"> </el-table-column>
      <el-table-column prop="phone" label="Телефон"> </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="'team-members/' + String(scope.row.id)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import { formatDateFullYear } from '@/helpers'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'
export default {
  mixins: [addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: {
    ActionButtons,
    Autocomplete,
  },
  data() {
    return {
      teamMembers: [],
      limit: 20,
      page: 1,
      total: 10,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.search = this.$route.query.search || ''
      this.limit = this.$route.query.limit || 20
      this.teamMembers = this.$route.query.teamMembers || []
    },
    formatDate(date) {
      return formatDateFullYear(date)
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }
      const { value, error } =
        await delivery.ContentServiceCore.TeamMembersActions.getList(query)
        this.updatedQueryParams(query);
      if (error) {
        loading.close()
        return
      }

      this.teamMembers = value.data
      this.total = value.meta.count
      loading.close()
    },

    async handleFindTeamMembers() {
      await this.getList()
    },
    handleSelectTeamMember(selectedUser) {
      this.$router.push('/addwine/content/team-members/' + selectedUser.id)
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.TeamMembersActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async remove(data) {
      const confirmed = confirm(`Вы точно хотите удалить ${data.name} ?`)

      if (confirmed) {
        const res = await delivery.ContentServiceCore.TeamMembersActions.delete(
          data.id,
        )
        if (!res.error) {
          this.$message({
            message: 'Сотрудник успешно удалён',
            type: 'success',
          })
          this.getList()
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.teamMembers {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
